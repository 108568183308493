import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Box, Card, CardContent, Typography, Divider } from '@mui/material';

export default function CouponDialog({isOpen,setOpenDialog,applyCoupon,showBreakdown,setShowBreakdown,breakDown,PayAndUpdatePlan}) {

  useEffect(() => {
    console.log({showBreakdown});
  }, [showBreakdown])
  
  const [couponCode, setCouponCode] = useState("");
  
  const handleClose = () => {
    setCouponCode("")
    setShowBreakdown(false)
    setOpenDialog(false)
  };

  const handleContinueButton = () => {
    PayAndUpdatePlan(couponCode)
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const coupon_code = formJson.coupon_code;
            setCouponCode(coupon_code)
            if(couponCode){
              applyCoupon(coupon_code);
            }
          },
        }}
      >
        <DialogTitle>Apply Coupon</DialogTitle>
        <DialogContent>
          <TextField
            placeholder="use nr3/customs/99off"
            autoFocus
            required
            onChange={e=>{setCouponCode(e.target.value)}}
            value={couponCode}
            margin="dense"
            id="coupon_code"
            name="coupon_code"
            label="Coupon Code"
            type="text"
            variant="standard"
          />
          {
            showBreakdown?<PriceBreakdown breakDown={breakDown}/>:""
          }
        </DialogContent>
        <DialogActions>

          {
            showBreakdown ? <Button onClick={()=>{handleClose()}}>Cancel</Button> : <Button type="submit">Apply</Button>
          }
          <Button variant="contained" onClick={()=>{handleContinueButton()}}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const PriceBreakdown = ({breakDown}) => {  
  return (
    <Card sx={{ maxWidth: 400, margin: 'auto', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography sx={{ color: 'green' }} variant="h6" gutterBottom>
          # Coupon Applied 
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Box display="flex" justifyContent="space-between" marginBottom={1}>
          <Typography>Price:</Typography>
          <Typography>${breakDown?.initial_amount}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom={1}>
          <Typography>Coupon Discount:</Typography>
          <Typography sx={{ color: 'green' }}>- ${breakDown?.coupon_amount}</Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Box display="flex" justifyContent="space-between"> 
        <Typography variant="h6">
          Amount to be paid: ${parseFloat(breakDown?.final_amount || 0).toFixed(1)}
        </Typography>

        </Box>
      </CardContent>
    </Card>
  );
};