import api from "./api";

class CouponService {

    CouponApply = async ({coupon_code, admin_id, plan_id}) => {
        const response = await api.post("/coupon/apply_coupon", {coupon_code, admin_id, plan_id});
        if (response.data) {
            return response.data;
        }
    }

}
export default new CouponService();